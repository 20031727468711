<template>
    <div class="online">
        <div class="ong" v-if="online"></div>
        <div v-else class="loader"></div> 
      </div>
  <div class="hed" @click="openNotif = true" :class="{anim:state.length > 0}">       
      <svg   v-show="!startActivity" :class="{animcolor:(!startActivity && state.length > 0)}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m5.705 3.71-1.41-1.42C1 5.563 1 7.935 1 11h1l1-.063C3 8.009 3 6.396 5.705 3.71zm13.999-1.42-1.408 1.42C21 6.396 21 8.009 21 11l2-.063c0-3.002 0-5.374-3.296-8.647zM12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.184 4.073 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z"/></svg>
      <p v-show="startActivity && state.length > 0">{{ state.length }}</p>

  </div>

  <transition name="slimeright">
      <slotokno :close="() => openNotif = false" :text="'Закрыть Уведомления'" v-if="openNotif">
        <div class="contentNorif">
          <div class="menuNot">
               <p class="nocopy" v-if="state.length > 0">Удалить все</p>
               <p v-else>Нет уведомлений</p>
         </div>
          <div class="oneNotification" v-for="one of state">
            <p class="close nocopy" @click="closeMessage(one._id)">X</p>
            <p class="title">{{ one.text }}</p>
            <div class="button" v-if="one.url !== null" style="margin-top:10px">Перейти</div>
          </div>
          
        </div>  
      </slotokno>
  </transition>
</template>

<script setup>
import {state,online,closeMessage} from '@/sse'
import {onMounted, ref, watch} from 'vue'

const startActivity = ref(false)
const openNotif = ref(false)
let intervalId = null

const toggleInterval = () => {
if (state.value.length == 0) {
  clearInterval(intervalId);
  startActivity.value = false; 
} else {
  intervalId = setInterval(() => {
      startActivity.value = true
      setTimeout(() => startActivity.value = false,1500)
  }, 3000);
  
}
};


watch(() => state.value, (n, o) => {
  console.log('YES')
  toggleInterval()
},{ deep: true });

onMounted(() => {
  toggleInterval()
})


</script>

<style scoped>
.hed{
  background: #fff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  margin-right: 10px;
  margin-top: -2px;
}
.hed svg{
  width: 19px;
  height: 19px;
}

.hed svg path{
  fill:var(--black);
  transition: 200ms;
}

.hed p{
  font-weight: 300;
}

.anim{

}
.animcolor{
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate-reverse both;
}
.animcolor path{
  fill:red !important;
  animation: li 500ms;
}

.anim p {

  animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
}

.ong{
  width: 9px;
  height: 9px;
  display: block;
  border-radius: 100%;
  background: green;
  margin-right: 10px;
}

.loader {
  width: 9px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid;
  border-color: #f12323 #9e9e9e54;
  animation: l1 1s infinite;
  margin-right: 10px;
  animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
@keyframes scale-up-center {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
          opacity: 0;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
}
}

.oneNotification{
    border-radius: 20px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 0px 8px 1px #e9e9e9;
    padding:20px;
    margin-top: 20px;
}

.online{
  transform: translateX(15px) translateY(-10px);
}

.contentNorif{
  padding-bottom: 20px;
}
.close{
    position: absolute;
    right: 18px;
    transform: translateY(-22px) translateX(10px);
    font-weight: 300;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    padding: 2px;
    font-size: 13px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 0px 8px 1px #e9e9e9;
}
.button{
    padding: 0px;
    color: var(--black);
    background: var(--blackgrey);
}
.menuNot{
    display: grid;
    align-items: center;
    justify-items: center;
    margin-top: 20px;
}
</style>