<template>
    <div class="oneOrder" @click="getOneOrder()">
    
        <div class="title botder grid">
            <p>{{ o.ordernumber }}<br>{{ o.crmnumber ? ` ${o.crmnumber}` : '' }}</p>
            <p class="right">{{ orderInfoStatus[o.status] }}</p>
        </div>
        
        <div class="grid" v-if="o.delivery.length > 0 && o.delivery[0].service">
          <p>Доставка:</p>
          <p style="font-weight: 300;" class="right">{{ deliveryName[o.delivery[0].service] }}</p>
        </div>
        <div class="grid">
            <p>Канал продажи:</p>
            <p class="right" style="font-weight: 300;">{{ channelInfo[o.channel] }}</p>
        </div>
        <div class="grid">
            <p>Дата:</p>
            <p class="right">{{ goodData(o.createdAt) }}</p>
        </div>
        <div class="grid">
            <p>Сумма:</p>
            <p class="right">{{ viewPriceAll }}</p>
        </div>
        <div class="grid">
            <p>Оплата:</p>
            <p class="right">{{ payMentsGood ? 'Оплачен' : 'Не оплачен' }}</p>
        </div>
        <div class="grid">
            <p>Товаров:</p>
            <p class="right">{{o.items.length}}</p>
        </div>
        <div class="YrLico" style="grid-column: 1 / -1;" v-if="o.payment.length > 0 && o.payment[0].service == 'bank-transfer'">
                       <p style="    background: #52db52;
    text-align: center;
    color: var(--blue);
    padding: 5px 0px;
    border-radius: 5px;">ЮР.ЛИЦО</p>
                    </div>
       
    </div>
    <transition name="slimeright">
        <slotokno :close="() => oneOrder = {}" :text="viewTextName" v-if="viewOknoOrder">
         <component :is="openOrder"  :d="oneOrder" @close="oneOrder= {}"/>
        </slotokno>
    </transition>

</template>

<script setup>

import { orderInfoStatus, channelInfo, goodData,deliveryName } from '@/controlles/general'
import { computed, ref } from 'vue'
import { activity } from '@/controlles/client'
import { channelRole,skladview } from '@/controlles/auth'
import allorder from '@/components/order/allorder.vue'
import returnorder from '@/components/order/returnorder.vue'
import sborka from '@/components/order/sborka.vue'
import axios from '@/utils/axios'
const url = `${process.env.VUE_APP_API_BD}`
const { o, options } = defineProps(['o', 'options'])
const oneOrder = ref({})
const viewTextName = ref('')
const viewPriceAll = computed(() => {
  let price = 0
  price = o.items.reduce((x, m) => x += m.price * m.quantity, 0)
  if (o.delivery.length > 0) { price = price + o.delivery[0].price }
  return price
})
const payMentsGood = computed(() => {
  let pament = false
  if (o.payment.length > 0) {
    let fullP = 0
    for (const one of o.payment) {
      if (one.status == 'paid') {
        pament = true
        fullP = fullP + one.price
      }
    }
    if (viewPriceAll.value !== fullP) {
      pament = false
    }
  }
  return pament
})
const openOrder = computed(() => {

  if (channelRole.value == 'warehouse' && o.warehouse == skladview.value) {
    if (options == 'return') {
      viewTextName.value = `Закрыть возврат ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return returnorder
    }
    if (options == 'sborka') {
      viewTextName.value = `Закрыть сборку ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return sborka
    }
    if(options == "default"){
      viewTextName.value = `Закрыть сборку ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return sborka
    }
  }
  if (channelRole.value == 'shop') {
    if (options == 'return') {
      viewTextName.value = `Закрыть возврат ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return returnorder
    }
    if (options == 'default' && o.warehouse == skladview.value) {
      viewTextName.value = `Закрыть заказ ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return allorder
    }
    if (options == 'sborka' && o.warehouse == skladview.value) {
      viewTextName.value = `Закрыть сборку ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
      return sborka
    }

  }
  viewTextName.value = `Закрыть заказ ${o.ordernumber} ${o.crmnumber ? ` / ${o.crmnumber}` : ''}`
  return allorder
})

const getOneOrder = async () => {
  try {
    const oneOrderGet = await axios.post(`${url}/bd`, {
      collection: 'order',
      filter: { _id: o._id },
      populate: [
        { path: 'contragent', select: ['firstname', 'lastname', 'phone', 'avatar'] },
        { path: 'items.productid', select: ['images','artikul','specifications'],populate:[{
          path:'model',select:['marking']
        }] }
      ]
    })
    oneOrder.value = oneOrderGet.data[0]
  } catch (e) {
  }
}

const viewOknoOrder = computed(() => !!Object.entries(oneOrder.value).length)

</script>

<style scoped>
.oneOrder{
    background: var(--white);
    padding:10px;
    width: calc(100% - 20px);
    margin-bottom: 20px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
}
.grid{
    display: grid;
    grid-template-columns: 1fr auto;
}

.botder {
    border-bottom: 1px solid var(--blackgrey);
}

.title{
    margin-bottom: 10px;
}

.title p:nth-child(1){
    font-weight: 300;
    font-size: 14px;
}
.right{
    text-align: right;
}
</style>
