<template>
    <div>
        <div class="itemsSborka">
            <p>{{ proverkaSborki.mass.filter(x => x == true).length }}</p><p>/</p><p>{{ d.items.length }}</p>
        </div>
        
        <Suspense>
                <div class="scanerBlock">
                      <scanner @barcode="getProductBarcode" :format="'ean_13'"/>
                 </div>
        </Suspense>
        <div class="blockRetunProduct">
            <div class="oneProduct" v-for="(one,index) of d.items" :key="one._id" :class="{good:proverkaSborki.mass[index]}">
                <transition name="slimeright"><div v-if="proverkaSborki.mass[index]" class="gooodsGalka">✔</div></transition>
                <img :src="`https://api.ultimatum.store/image/${one.productid.images[0]}?quality=100&width=160&format=jpeg`">
                <div class="productName">
                    <p style="font-weight: 300;">{{ one.name }}</p>
                    <p>Артикул: {{viewArtikul(one)}}</p>
                    <p>Кол-во:{{one.quantity}}</p>
                    <div class="viewM">
                           <p>Собрано:</p>
                           <input type="number" v-model="one.quan">
                    </div>
                </div>           
                <div class="chestznak" v-if="one.marking">
                <div class="cheststatus">
                    <p>Честный знак:</p><p style="font-size:13px">{{one.marking_code == null ? 'Не отсканирован' : one.marking_code_decode.substring(0, 31) }}</p>
                </div>
                <div class="button clickbutton" @click="viewScaner.view = true,viewScaner.id = one._id">{{ one.marking_code == null ? 'Отсканировать' : 'Изменить' }}</div>
            </div>
            </div>
        </div>

                 <transition name="slimetop">
                    <div class="blockscanner" v-if="viewScaner.view">
                            <p @click="viewScaner.view = false,viewScaner.id = null">Закрыть</p>
                            <Suspense>
                                <scanner :format="'data_matrix'" @barcode="getDataMatrix"/>
                            </Suspense>
                        </div>
                </transition>

                <transition name="slimetop">
                    <div class="getZnak" v-if="getZnak.view">
                        <div class="infoBlockGet">
                           <div class="information">
                                <span class="loader" v-if="getZnak.loading"></span>
                                <p class="good" v-if="!getZnak.loading && getZnak.good">Честный знак одобрен</p>
                                <p class="error" v-if="!getZnak.loading && !getZnak.good">Ошибка одобрения</p>
                                <p>{{ getZnak.comment }}</p>

                           </div>
                           <div class="button clickbutton nocopy" @click="getZnak.view = false">Закрыть</div>
                        </div>
                    </div>
                </transition>
                <transition name="slimetop">
              <div class="button classM nocopy" v-if="proverkaSborki.status" @click="goodSborka()">ЗАКАЗ СОБРАН</div>
      </transition>
    </div>
</template>
<script setup>
import scanner from '@/components/general/scanner.vue'
import clientinfo from '@/components/order/clientinfo.vue'
import { notSet, setSuc, loading,noloading,updateFunction } from '@/controlles/general'
import axios from '@/utils/axios'
import {ref,onMounted,computed} from 'vue'
const { d } = defineProps(['d'])
const viewScaner = ref({
    view:false,
    id:null
})
const emit = defineEmits(['close'])
const getZnak = ref({
  view: false,
  comment: '',
  loading: true,
  good: false,
  comment: 'Проверяем данные честного знака'
})


const goodSborka = async () => {
    try{
    var complectProduct = []
        for(let one of d.items){
            complectProduct.push({
                _id:one._id,
                marking:one.marking,
                marking_code:one.marking_code,
                marking_code_decode:one.marking_code_decode,
                marking_uuid:one.marking_uuid
            })
        }
    await axios.post(`https://api.ultimatum.store/warehouse/completedorder`,{
                id:d._id,
                items:complectProduct
    }) 
    if(['osnovnoi'].includes(d.warehouse)){
       await axios.get(`https://api.ultimatum.store/printlabel/arm?id=${d.delivery[0]}`)
    }
    emit('close')
    updateFunction('collect')
    }catch(e){
        console.log(e)
        notSet('Ошибка изменения заказа, попробуйте еще раз')
        return
    }

}

const viewArtikul = (one) => {
 
    if(one.specification !== null){
        var artikul = one.productid.specifications.find(x => x._id == one.specification)
        return artikul.artikul
    }
    return one.productid.artikul
}

const proverkaSborki = computed(() => {
    var mass  = []
    for(let one of d.items){
      if(one.quantity == one.quan) {
        if(one.marking){
            if(one.marking_code == null){
                mass.push(false)
            }else{
                mass.push(true)
            }
        }else{
            mass.push(true)
        }
      }else{
        mass.push(false)
      }
    }
    if(mass.every((element) => element === true)) return {
        status:true,
        mass:mass
    }
    return {
        status:false,
        mass:mass
    }
})

const getDataMatrix = async (data) => {
  noloading.value = true
  var viewproduct = d.items.find(x => x._id == viewScaner.value.id)
  viewproduct.marking_code = window.btoa(unescape(encodeURIComponent(data)))
  viewproduct.marking_code_decode = data
  viewScaner.value.view = false
  getZnak.value.view = true
  getZnak.value.loading = true

  const getZnakData = await axios.post('https://api.ultimatum.store/arm/znak/check', { marking: data })
  if (getZnakData.status == 'good') {
    viewproduct.marking_uuid = getZnakData.uuid
    getZnak.value.good = true
    getZnak.value.loading = false
    getZnak.value.comment = ''
    viewScaner.value.view = false
    viewScaner.value.id = null
  }
  if (getZnakData.status == 'error') {
    getZnak.value.good = false
    getZnak.value.loading = false
    getZnak.value.comment = getZnakData.comment
  }
  noloading.value = false
}
const getProductBarcode = async (barcode) => {
 try {
   const otvet = await axios.post('https://api.ultimatum.store/arm/shop/barcode', { barcode: barcode })
   if (otvet.status == 'error') {
     notSet('Штрихкод не распознан')
     return
   }
    if(otvet.product.activityspecifications){
            var guids = null
                for(let two of otvet.product.specifications){
                    if(two._id == otvet.spec){guids = `${otvet.product.guid}#${two.guid}`}
                }
                for(let one of d.items){
                        if(one.guid == guids){
                            console.log(one)
                            one.quan++
                            notSet('Товар добавлен')
                            return
                        }
                }
    }else{
            for(let one of d.items){
                        if(one.guid == `${otvet.product.guid}`){
                            one.quan++
                            notSet('Товар добавлен')
                            return
                        }
                }
    }
    notSet('Товар не найден в заказе')
 } catch (e) {
   console.log(e)
 }
}

onMounted(() => {
    for(let one of d.items){
        one['quan'] = 0
        one['sta'] = false
        if(one.productid.model.marking){
            one['marking'] =true
        }
    }
})
</script>
<style scoped>

.blockRetunProduct{
    display: grid;
    gap:10px;
    padding-bottom: 100px;
    margin-top: 88%;
}
.oneProduct{
    width: calc(100% - 20px);
    background: var(--white);
    padding:10px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap:10px;
    align-items: center;
}
.oneProduct img{
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.blockscanner{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: grid;
    top: 0;
    left: 0;
    background: var(--black);
    z-index: 999999;
}

.blockscanner p {
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-left: 20px;
}

.getZnak{
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 100vh;
    background: var(--white);
    z-index: 999;
    padding: 20px;
    display: grid;
    align-items: center;
}
.loader {
    width: 25px;
    height: 25px;
    border: 2px solid var(--lightblue);
    border-bottom-color: var(--black);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.information{
    display: grid;
    gap: 10px;
    text-align: center;
    justify-items: center;
    margin-bottom: 40px;
}
.good{
    color: var(--green);
}
.error{
    color: red;
}

.chestznak{
    grid-column: span 2;
    border: 1px solid var(--blackgrey);
    border-radius: 10px;
}

.cheststatus{
    grid-template-columns: 120px 1fr;
    display: grid;
    text-align: right;
    align-items: center;
    margin: 10px 0px;
    padding: 0px 10px;
    width: calc(100% - 20px);
    gap: 10px;
}

.cheststatus p {
    font-size: 18px;
    font-weight: 300;
    word-break: break-all;
}
.viewM{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: center;
    justify-items: center;
    padding-bottom: 2px;
}
.classM{
    position: fixed;
    bottom: 10px;
    width: calc(100% - 20px);
}
.good{

}
.gooodsGalka{
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--green);
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    color: var(--white);
    transform: translateY(-299%);
    margin-left: -4px;
    font-size: 14px;
}
.scanerBlock{
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 2;
}
.itemsSborka{
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 2px;
    position: fixed;
    top: 14px;
    right: 10px;
    z-index: 99999999;
    background: var(--white);
    padding: 5px 10px;
}
</style>