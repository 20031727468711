export default [
  {
    path: '/delivery/kdostavke',
    name: 'delivery-kdostavke',
    component: () => import('../views/delivery/delivery.vue')
  },
  {
    path: '/delivery/nadostavku',
    name: 'delivery-nadostavku',
    component: () => import('../views/delivery/delivery.vue')
  },
  {
    path: '/delivery/dostavit',
    name: 'delivery-dostavit',
    component: () => import('../views/delivery/delivery.vue')
  },
  {
    path: '/delivery/report',
    name: 'delivery-report',
    component: () => import('../views/warehouse/instok.vue')
  },

]
