import axios from '@/utils/axios'
import { useCookies } from 'vue3-cookies'
import router from '@/router'
import state from './state'
import { notSet } from '@/controlles/general'
import { getCategoryAll } from '@/controlles/product'

const url = `${process.env.VUE_APP_API_BD}`

const { user, role, setUser, activity, channelRole,skladview } = state()

const { cookies } = useCookies()

const login = async (data) => {
  try {
    const getToken = await axios.post(`${url}/armauth/log`, data)
    const user = await axios.post(`${url}/armauth/token`, { token: getToken.token })
    setUser(user)
    cookies.set('user', getToken.token, 60 * 60 * 24)
    router.push({ name: 'home' })
    await getCategoryAll()
  } catch (e) {
    alert('Ошибка авторизации')
    setUser({})
  }
}

const updateLogin = async () => {
  try {
    const user = await axios.post(`${url}/armauth/token`, { token: cookies.get('user') })
    setUser(user)
    await getCategoryAll()
  } catch (e) {
    cookies.remove('user')
    setUser({})
    router.push({ name: 'auth' })
  }
}

const saveUser = async (data) => {
  try {
    console.log(user)
  } catch (e) {}
}

const proverkaCookies = () => {
  const user = cookies.get('user')
  if (user) { return true }
  return false
}

const exitUser = () => {
  cookies.set('user', null, 60 * 60 * 24)
}

export {
  login,
  updateLogin,
  proverkaCookies,
  saveUser,
  user,
  role,
  activity,
  channelRole,
  exitUser,
  skladview
}
