<template>
    <div class="oneOrder">
        <img :src="`https://api.ultimatum.store/image/${d.productid.images[0]}?quality=100&width=160&format=jpeg`">
        <div class="block">
        <div class="titles">{{d.name}}</div>
        <div class="grid">
            <p>Количество</p><p class="right">{{ d.quantity }}</p>
        </div>
        <div class="grid">
            <p>Стоимость</p><p class="right">{{ d.price }}</p>
        </div>
        <div class="grid">
            <p>Общая стоимость</p><p class="right">{{ d.quantity*d.price }}</p>
        </div>

        </div>

    </div>
</template>

<script setup>
const { d } = defineProps(['d'])
</script>

<style scoped>
.oneOrder{
    display: grid;
    grid-template-columns: 80px 1fr;
    align-items: center;
    gap:20px;
    padding: 10px;
    width: calc(100% - 20px);
    background: var(--white);
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
}
.grid{
    display: grid;
    grid-template-columns: 1fr auto;
}
.right{
    text-align: right;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
