import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
// import './registerServiceWorker'
import AppLayout from './layouts/AppLayout'
import slotokno from './components/general/slotokno'
import { createYmaps } from 'vue-yandex-maps';

createApp(App)
  .use(router)
  .use(createYmaps({
    apikey: 'e9ee4f36-6920-4f49-998d-0d5370ee9ea3',
  }))
  .component('AppLayout', AppLayout)
  .component('slotokno', slotokno)
  .mount('#app')
