import { defineAsyncComponent } from 'vue'
const bLock = () => {
  return {
    searchOrder: defineAsyncComponent(() => import('./searchOrder.vue')),
  }
}

export {
  bLock
}
